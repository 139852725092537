import { render, staticRenderFns } from "./feedback_comenzi.vue?vue&type=template&id=c0dd7e80"
import script from "./feedback_comenzi.vue?vue&type=script&lang=js"
export * from "./feedback_comenzi.vue?vue&type=script&lang=js"
import style0 from "./feedback_comenzi.vue?vue&type=style&index=0&id=c0dd7e80&prod&rel=stylesheet%2Fscss&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports