<template>
	<div class="app-container">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('comm.feedback.header')}}</span>
			</div>
			<template v-if="noFeedback">
				<el-alert :title="$t('general.warning')" :closable="false" type="warning" :description="$t('comm.feedback.warning-desc')" show-icon>
				</el-alert>
			</template>
			<template v-else>
				<el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage" :total="count"
				 :page-size="limit" @current-change="changePage">
				</el-pagination>
				<div class="mini-separator"></div>
				<el-table :data="feedbacks" style="width: 100%" size="small">
					<el-table-column width="150px" prop="orders_id" :label="$t('comm.feedback.order-id')">
						<template slot-scope="scope">
							<router-link :to="'/vanzari/comanda/' + scope.row.orders_id">
								{{scope.row.orders_id}}
							</router-link>
						</template>
					</el-table-column>
					<el-table-column prop="data" :label="$t('comm.feedback.date')">
					</el-table-column>
					<el-table-column prop="vote" label="Rating">
						<template slot-scope="scope">
							<el-rate v-model="scope.row.vote" disabled show-score text-color="#ff9900" score-template="">
							</el-rate>
						</template>
					</el-table-column>
					<el-table-column prop="feedback" label="Feedback">
						<template slot-scope="scope">
							<span v-html="scope.row.feedback"></span>
						</template>
					</el-table-column>
				</el-table>
				<div class="mini-separator"></div>
				<el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage" :total="count"
				 :page-size="limit" @current-change="changePage">
				</el-pagination>
			</template>
		</el-card>
	</div>
</template>

<script>
	import {
		getOrdersFeedback
	} from '@/api/comunicare';
	import has from 'lodash.has';
	export default {
		data() {
			return {
				feedbacks: [],
				count: 0,
				currentPage: 1,
				limit: 50,
				start: 0
			};
		},
		methods: {
			getOrdersFeedback() {
				const params = { start: this.start, withCount: true };
				getOrdersFeedback(params).then((res) => {
					if (!has(res, 'message')) return false;
					if (parseInt(res.error) > 0) return false;
					this.feedbacks = res.message.data;
					this.count = res.message.count;
				});
			},
			changePage(page) {
				this.start = page * this.limit - this.limit;
				this.currentPage = page;
				this.getOrdersFeedback();
			}
		},
		computed: {
			noFeedback() {
				return this.feedbacks.length < 1;
			}
		},
		mounted() {
			this.getOrdersFeedback();
		}
	};
</script>
<style rel="stylesheet/scss" lang="scss">
 @import './src/styles/modules/comunicare.scss'
</style>
